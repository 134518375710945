//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'
import project from '~/static/config/project.json'

export default {
  components: {
    LazyHydrate
  },
  mixins: [global],
  async asyncData ({ params, route, app, store, error, $axios, $md5, $config }) {
   // Params & Props
    const routeProps = route.matched[0].props.default
    // const TypeId = routeProps.prodTypeId
    //let Type = routeProps.Type
   
    // Sections Data
    const sectQry = $config.static + '/json/questions/sections.json'
    const sectCode = $md5(sectQry)
    if (typeof store.state.lists[sectCode] === 'undefined' || Object.keys(store.state.lists[sectCode]).length === 0) {
      await store.dispatch('loadLists', sectQry)
    }
    const sections = store.state.lists[sectCode].filter((section) => {
      return section.active !== '0'
    })
   
    // Set Section
    let section = []
    sections.forEach((s) => {
      const er = new RegExp(`^${s.path}`)
      const match = er.exec(route.path)
      if (match) {
        section[match[0].length] = s
      }
    })
    
    let arraySection  = []
      section.forEach( (s1) =>{
        arraySection.push(s1)
        }
       );

    section = section.pop()
    // Zones & Heritages
    const ax = new AxiApi($config.api)
    const call = $config.static + '/json/zones/inquiry/zones.json'
    let splitRouteReplace
    let zones
    try {
      let ruta = route.path
      const routerepalce = ruta.replace('//', '/');
      const filtro = sections.filter(s => s.id === route.params.pathMatch || routerepalce.includes(s.path));      
      splitRouteReplace = routerepalce.split('/').filter(element => element !== '');
      const filtroSection = filtro.filter(as=> as.path.split('/').filter(element => element !== '') === splitRouteReplace);
      const urlTodas= '/'+project.contents.inquiries.all.paths.home;
    
      const match = urlTodas.split("/").filter(u => u.trim());
      const coincidenciaTotal = match[match.length -1]
      
      const hasFilter = filtro.length > 0;
      const hasSections = filtroSection.length > 0;
      const hasEmptySection = arraySection.length === 0;
      const matchesRoute = splitRouteReplace[splitRouteReplace.length-1] === coincidenciaTotal || urlTodas === routerepalce || urlTodas === routerepalce + '/';
      const matchesRouteFilter = splitRouteReplace.length === 4 && !matchesRoute && splitRouteReplace[splitRouteReplace.length-2] === coincidenciaTotal ;
      const hasShortRoute = splitRouteReplace.length < 8;
      const matchesArraySection = arraySection.length > 0 && routerepalce.includes(arraySection[0].path) && arraySection[0].path.split('/').filter(element => element !== '')[2] === splitRouteReplace[2];
      let page;
      let filtersUrl = route.fullPath
      if (filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)) {
        const pathMatches = filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)
        page = (pathMatches[1] !== undefined) ? pathMatches[1] : pathMatches[2] !== undefined ? pathMatches[2] : ''
      }

     if ((hasFilter
            && ( hasSections || (hasEmptySection && matchesRoute) || (!matchesRoute && splitRouteReplace.length === 2 ))
            && hasShortRoute)
        || (hasFilter && matchesArraySection && hasShortRoute) || (hasFilter && hasEmptySection && splitRouteReplace.length === 3 && page)
        || (hasFilter && hasEmptySection && splitRouteReplace.length === 3 && matchesRoute)
        || (hasFilter && hasEmptySection && splitRouteReplace.length === 4 && matchesRouteFilter )) {
       zones = await $axios.get(call)
      } else {
        return error({ statusCode: 404, message: '' })
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
    await ax.createComponentData(zones.data, store)   

    // BreadCrumb
    let breadcrumb = []
    if (section) {
      breadcrumb = breadcrumb.concat(routeProps.breadcrumb)
      breadcrumb.push({
        text: section.name,
        href: section.path,
        active: true
      })
    }

    // Busqueda texto
    let querySearch = ''
    if (typeof route.query.q !== 'undefined' && route.query.q !== '') {
      querySearch = route.query.q
    }

    // Get inquiries API data
    let page = 1
    let productID = ''
    let filtersUrl = route.fullPath

    if (section && section?.path !== '') {
      filtersUrl = filtersUrl.replace(`${section.path}/`, '')
    }
   
    if (filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)) {
      const pathMatches = filtersUrl.match(/\/(\d{1,3})\/|^(\d{1,3})\//)
      page = (pathMatches[1] !== undefined) ? pathMatches[1] : pathMatches[2] !== undefined ? pathMatches[2] : ''
    }
    
    if (filtersUrl !== '' && filtersUrl.match(/^.+?-([0-9]+)\//)) {
      const pathMatches = filtersUrl.match(/^.+?-([0-9]+)\//)
      productID = pathMatches[1]
    }
   
    let product = false
    if (!store.state.productsLoaded) {
      await store.dispatch('loadProducts')
    }
    product = store.state.products.find(p => p.content_id === productID)

    let filters2api = ''
    if (section && section.content_id && productID === '' && section.id !== '') {
      filters2api += `/section-${section.content_id}`
    } else if (routeProps.sections && routeProps.sections.match(/([0-9]+)/)) {
      filters2api += `/section-${routeProps.sections}`
    }
   
    const query = `${$config.api}/app/v1/users/chats?_p=${$config.id}&filters=${filters2api}&page=${page}&text=${querySearch}&products=${product?.content_id ?? ''}&order=date&direction=desc`
    console.log(query)
    const code = $md5(query)
    if (
      typeof store.state.lists[code] === 'undefined' ||
      Object.keys(store.state.lists[code]).length === 0
    ) {
      try {
        await store.dispatch('loadLists', query)
      } catch (err) {
        return error({ statusCode: 404, message: err.message })
      }
     }

    const inquiries = store.state.lists[code]
    if (!inquiries || !inquiries.pagination){   
      return error({ statusCode: 404, message: '' })
    }

    const total = inquiries.total
    if(total > 0){
      const porPagina = inquiries.pagination.contents 
      const totalPaginas = Math.ceil(total / porPagina)
    
      if (!(page > 0 && page <= totalPaginas)){  
        return error({ statusCode: 404, message: '' })
      }
    }
    
    // Metas & Head
    const producto = product ? ` ${product.brand} ${product.title}` : ''
    const dinamicSeo = section ? section.metas.title.replace(/#producto#/g, producto).replace(/#texto#/g, ` ${querySearch}`) : app.i18n.t('product_page.inquiries')

    const header = {
      h1: dinamicSeo
    }
    const head = {
      title: app.i18n.t('inquiry_list.meta_title').replace(/{seo}/g, dinamicSeo),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: app.i18n.t('inquiry_list.meta_title').replace(/{seo}/g, dinamicSeo)
        },
        {
          hid: 'description',
          name: 'description',
          content: (section && section.metas?.description !== '') ? section.metas.description : app.i18n.t('inquiry_list.meta_description')
        }
      ]
    }

    if (Object.keys(route.query).length > 0) {
      head.meta.push(
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        }
      )
    }

    const zonesContent = ax.get()

    if (!app.$isAMP) {
      head.style = await cssFunctions.criticals(route.path, 'inquiry_list', {
        ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0
      })
    }

    return {
      breadcrumb,
      inquiries,
      header,
      head,
      zones: zonesContent,
      sections,
      currentSection: section
    }
  },
  data () {
    return {
      sections: []
    }
  },
  head () {
    return this.head
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
  }
}
